<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Importación de facturas</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <template v-if="status">
            <v-row class="mx-0">
              <v-col cols="12" class="white">
                <v-list class="v-list-form-extended transparent">
                  <v-list-item>
                    <v-list-item-title>Estatus de la importación</v-list-item-title>
                    <v-list-item-subtitle>
                      <code>{{status}}</code>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Facturas importadas</v-list-item-title>
                    <v-list-item-subtitle>{{count}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-divider />
            <template v-if="issues.length">
              <v-toolbar color="transparent" flat height="55">
                <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Problemas</v-toolbar-title>
              </v-toolbar>
              <v-row>
                <v-timeline dense>
                  <v-timeline-item v-for="(d, i) in issues" :key="i" color="grey-500" fill-dot right small>
                    <v-card class="elevation-1">
                      <v-card-title class="body-2 grey-500--text font-weight-medium pb-0 pt-3">{{d.file_name}}</v-card-title>
                      <v-card-text class="pb-3">
                        <span class="d-block" v-for="(issue, n) in d.issues" :key="n">{{issue}}</span>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-row>
            </template>
          </template>
          <v-col cols="6" class="mx-auto" v-else>
            <v-list class="transparent">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-file-input v-model="files" type="file" accept=".xml" multiple prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                  </v-list-item-title>
                  <v-list-item-subtitle class="pt-3">
                    <span class="font-weight-medium">Selecciona hasta 100 archivos por cada importación.</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="clearImport" v-if="status" :loading="$store.state.accounts.createLoader" color="blue-500">Nueva importación</v-btn>
          <v-btn @click="createImport" v-else :loading="$store.state.accounts.createLoader" color="blue-500">Importar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    files: null,
    status: null,
    count: null,
    issues: []
  }),
  methods: {
    createImport () {
      if (!this.files.length || this.files.length > 100) {
        return false
      }
      const payload = new FormData()
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i]
        payload.append(`files[${i}]`, file)
      }
      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/imports/documents',
        payload: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.status = response.data.status
        this.count = response.data.count
        this.issues = response.data.issues
      })
    },
    clearImport () {
      this.files = null
      this.status = null
      this.count = null
      this.issues = []
    }
  }
}
</script>